import http from '@/axios/http'
import userConfig from '@/config/user.config.json'

const context = userConfig[process.env.NODE_ENV].VUE_AUTH_SERVER_HOST
const base_url = `${context}/api/user`

export default {
    /**
     * 히스토리 등록
     * @returns 
     */
    setLoginHistory() {
        return http.post(base_url);
    },
    /**
     * 사용자 정보 조회
     * @returns 
     */
    getUserInfo() {
        return http.get(base_url);
    },
    /**
     * 비밀번호 변경
     * @param {*} param 
     * @returns 
     */
    changePassword(param) {
        return http.post(`${base_url}/password/change`, JSON.stringify(param), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    },
    /**
     * 탈퇴
     * @returns 
     */
    drop() {
        return http.delete(base_url)
    }
}