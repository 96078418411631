import { createRouter, createWebHistory } from 'vue-router'
import authService from '@/services/authService'
import tokenUtil from '@/util/token'
import { useAuthStore } from '@/store/useAuthStore'

// google 승인 후 주석해제
const routes = [{
	path: '/',
	name: '',
	component: () => import('@/layout/DefaultLayout.vue'),
	children: [ {
		path: '',
		name: 'main',
		component: () => import('@/components/user/LoginComponent.vue')
	}, {
		path: '/signup',
		name: 'signup',
		component: () => import('@/components/user/SignUpComponent.vue')
	}, {
		path: '/store',
		name: 'store',
		component: () => import('@/components/user/StoreComponent.vue')
	}, {
		path: '/find',
		name: 'find',
		children: [{
			path: 'password',
			name: 'findPassword',
			component: () => import('@/components/user/FindPasswordComponent.vue')
		}, {
			path: 'email',
			name: 'findEmail',
			component: () => import('@/components/user/FindEmailComponent.vue')
		}]
		
	} ]
}, {
	path: '/land',
	name: 'land',
	component: () => import('@/layout/MapLayout.vue')
}]


const router = createRouter( {
	history: createWebHistory(process.env.BASE_URL),
	routes
} );


router.beforeEach((to, from, next) => {
	const path = to.path;

	if (path === '/' || path === '/signup' || path === '/find/password' || path === '/find/email') {
		next();
		
	} else {
		const token = localStorage.getItem('access_token');
		
		if (token == null) {	// 로그인이 안된 경우
			next("/");
		} else {
			const authStore = useAuthStore();
			tokenUtil.setAuthorization(token);

			authStore.checkToken()
			.then(() => {
				next();
			});
		}
	}
});



export default router
