import { useNotification } from "@kyvg/vue3-notification";

const notification = useNotification();

export default {
    success: (msg) => {
        notification.notify({
            type: 'success',
            title: "Success",
            text: msg,
            speed: 500,
            duration: 2000
        });
    },
    error: (msg) => {
        notification.notify({
            type: 'error',
            title: "Error",
            text: msg,
            speed: 500,
            duration: 2000
        });
    },
    warn: (msg) => {
        notification.notify({
            type: 'info',
            title: "Info",
            text: msg,
            speed: 500,
            duration: 2000
        });
    }
}