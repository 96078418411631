// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import '@/assets/style/common.scss'

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify( {
	theme: {
		themes: {
			light: {
				colors: {
					primary: '#0CBDF6',
					secondary: '#686AF0',
					accent: '#ff6058',
					dark: '#616161',
				},
			},
		},
	}
} )
