import { VueElement, createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { createPinia } from 'pinia'
import { loadFonts } from './plugins/webfontloader'
import './assets/style/common.scss'
import Notifications from '@kyvg/vue3-notification'

loadFonts()
const pinia = createPinia()

createApp(App)
	.use(router)
	.use(vuetify)
	.use(pinia)
	.use(Notifications)
	.mount('#app');
