import { defineStore } from 'pinia'
import authService from '@/services/authService'
import userService from '@/services/userService'
import JSEncrypt from "jsencrypt"
import token from '@/util/token'

export const useAuthStore = defineStore( 'authStore', {
    state: () => ({
        authUser: {
            visible: false,     // 팝업
            value: '',          // 인증코드
            success: false,     // 성공여부
            loading: false     // 이메일 발송시 로딩상태
        }
    }),
    getters: {},
    actions: {
        /**
         * 회원가입
         * @param {*} params 
         */
        async signup(params) {
            try {
                const key = await authService.key();
                const encrypt = new JSEncrypt();
                encrypt.setPrivateKey(key.data);

                await authService.signup({
                    email: params.email,
                    username: params.username,
                    password: encrypt.encrypt(params.password),
                    tel: params.tel.replaceAll("-", "")
                });

            } catch (error) {
                throw error;
            }
        },

        /**
         * 로그인
         * @param {*} params 
         */
        async login(params) {
            try {
                const key = await authService.key();
                const encrypt = new JSEncrypt();
                encrypt.setPrivateKey(key.data);
    
                const res = await authService.login({
                    username: params.username,
                    password: encrypt.encrypt(params.password)
                });

                token.save( {
                    access_token: res.data.access_token,
                    refresh_token: res.data.refresh_token
                } );

                token.setAuthorization(res.data.access_token);
                userService.setLoginHistory();
                
            } catch(error) {
                throw error;
            }
        },

        /**
         * token 유효성 체크
         */
        async checkToken() {
            let accessToken = token.getAccessToken();
            
            if (!accessToken) {
                token.remove();

            } else {
                try {
                    let res = await authService.checkToken(accessToken);
                } catch (error) {
                    token.remove();
                    throw error;
                }
            }
        },

        /**
         * 인증코드 발송
         * @param {*} params 
         */
        async sendEmailCode(params) {
            try {
                await authService.sendEmailCode(params);
                this.authUser.visible = true;

            } catch (error) {
                throw error;
            }
        },

        /**
         * 인증코드 검증
         * @param {*} params 
         */
        async emailCodeCheck(params) {
            try {
                const res = await authService.emailCodeCheck(params);
                this.authUser.success = res.data;

            } catch (error) {
                throw error;
            }
        }

        /**
         * refresh token 조회
         */
        // async refreshToken() {
        //     let refresh_token = token.getRefreshToken();
        //     if ( !refresh_token ) {

        //     } else {
        //         try {
        //             let res = await authService.refreshToken( refresh_token )
        //             token.save( {
        //                 access_token: res.data.access_token,
        //                 refresh_token: res.data.refresh_token
        //             } );

        //             token.setAuthorization();
        //         } catch (error) {
        //             token.remove();
        //             throw error;
        //         }                             
        //     }
        // }
    }
} )