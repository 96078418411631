import http from '@/axios/http'

const storage = window.localStorage;
const getTokenByType = (type) => {
    return storage.getItem(type);
}

export default {
    /**
     * 토큰 저장
     * @param {*} token 
     */
    save( token ) {
        ['access_token', 'refresh_token'].forEach( key => {
            storage.setItem(key, token[key]);
        } );
    },

    /**
     * 토큰 삭제
     */
    remove() {
        ['access_token', 'refresh_token'].forEach( key => {
            storage.removeItem(key);
        } );
    },

    /**
     * access_token 조회
     * @returns 
     */
    getAccessToken() {
        return getTokenByType('access_token');
    },
    getRefreshToken() {
        return getTokenByType('refresh_token');
    },
    setAuthorization(access_token) {
        let token;
        if (access_token) {
            token = 'Bearer ' + access_token;
        }
        http.defaults.headers.common.Authorization = token;
    }
}