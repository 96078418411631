<template>
	<v-app>
		<v-main>
			<router-view/>

			<notifications 
				position="top center" />
		</v-main>
	</v-app>
</template>

<script>
import http from './axios/http'
import token from './util/token'
import authService from './services/authService'
import message from './util/message'
import { useRouter } from 'vue-router'

// 토큰 재발급 후 재호출
let retryRequest = ({data}, {config}) => {
    token.save(data);
    config.headers.Authorization = [data.token_type, data.access_token].join(' ');
    return http.request(config);
}

// refresh token 호출
let getTokenByRefreshToken = () => {
    let refresh = token.getRefreshToken();
    return authService.refreshToken(refresh);
};

export default {
  	name: 'App',
	setup() {
		const router = useRouter();

		http.interceptors.response.use(null, (error) => {
			const description = error.response.data.error_description;
			const status = error.response.status;
			if (status === 400) {
				if (description.indexOf("Token has expired") > -1) {
					message.error("사용자 토큰 만료로 로그인창으로 이동합니다.");
					token.remove();
					router.push("/");
				}

			} else if (status === 401) {
				if (description.indexOf("Access token expired") > -1) {								// access token 만료
					return getTokenByRefreshToken()
							.then(res => retryRequest(res, error))
		
				} else if (description.indexOf("Invalid refresh token") > -1) {						// refresh token 만료
					message.error("사용자 토큰 만료로 로그인창으로 이동합니다.");
					token.remove();
					router.push("/");
				}
			}

			return Promise.reject(error);
		});
	}
}
</script>

<style>
.vue-notification {
	margin-top: 20px !important;
}
</style>
