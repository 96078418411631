import http from '@/axios/http'
import axios from 'axios'
import userConfig from '@/config/user.config.json'
import qs from 'qs'

const basic_auth = userConfig.basic_auth;
const base_url = userConfig[process.env.NODE_ENV].VUE_AUTH_SERVER_HOST;

const options = {
    auth: basic_auth,
    headers: {
        'content-type': 'application/x-www-form-urlencoded'
    }
};

export default {
    key() {
        return axios.get(`${base_url}/public/rsa/key`);
    },
    signup(params) {
        return axios.post(`${base_url}/public/signup`, JSON.stringify(params), {
            'headers': {
                'Content-Type': 'application/json'
            }
        });
    },
    login({ username, password }) {
        return http.post(
            `${base_url}/oauth/token`,
            qs.stringify({
                username: username,
                password: password,
                grant_type: 'password'
            }),
            options
        );
    },
    checkToken(access_token) {
        return http.post(
            `${base_url}/oauth/check_token`,
            qs.stringify({token: access_token}),
            options
        );
    },
    refreshToken(refresh_token) {
        return http.post(
            `${base_url}/oauth/token`,
            qs.stringify({
                refresh_token: refresh_token,
                grant_type: 'refresh_token'
            }),
            options
        );
    },
    findUserEmail(params) {
        return http.post(`${base_url}/public//find/email`, JSON.stringify(params), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    },
    // TODO 비밀번호 찾기
    findPassword(params) {
        return http.post(`${base_url}/public//find/password`, JSON.stringify(params), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    },
    // 회원가입 인증 메일 발송
    sendEmailCode(params) {
        return http.post(`${base_url}/public/auth/send`, JSON.stringify(params), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    },
    // 인증코드 검증
    emailCodeCheck(params) {
        return http.post(`${base_url}/public/auth/codecheck`, JSON.stringify(params), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

}